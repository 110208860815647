<template>
  <v-row align="center" justify="center" no-gutters>
    <v-card class="green accent-1 px-md-16" flat tile width="100%">
      <v-row align="center" class="px-md-16" justify="center" no-gutters>
        <v-col v-if="$vuetify.breakpoint.mdAndUp" class="me-n16" md="4">
          <v-img :aspect-ratio="1/1.5" class="me-n16" contain height="200" src="@/assets/images/contact-us.png"/>
        </v-col>
        <v-col class="text-start" cols="10" lg="7" md="8">
          <v-card-title class="text-h4" v-text="$vuetify.lang.t('$vuetify.pages.home.contactUsPromo.title')"/>
          <v-card-subtitle class="text-h6"
                           v-text="$vuetify.lang.t('$vuetify.pages.home.contactUsPromo.subtitle')"/>
          <v-card-text>
            <v-btn :to="{name: 'contact'}" class="green accent-5" dark x-large>
              {{ $vuetify.lang.t('$vuetify.pages.home.contactUsPromo.btnText') }}
            </v-btn>
          </v-card-text>
        </v-col>
        <v-col v-if="!$vuetify.breakpoint.mdAndUp" cols="10" md="4">
          <v-img class="rounded-xl" contain src="@/assets/images/contact-us.png"/>
        </v-col>
      </v-row>
    </v-card>
  </v-row>
</template>

<script>
export default {
  name: 'KurccContactUsPromo',
  computed: {
    height () {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 340
        case 'sm':
          return 340
        case 'md':
          return 340
        case 'lg':
          return 275
        case 'xl':
          return 275
        default:
          return 340
      }
    }
  }
}
</script>
